<template>
<div>
  <tag-big :navData="navData" :self="true"></tag-big>
  <router-view></router-view>
</div>
</template>

<script>
import TagBig from '@/components/tag_big'

export default {
name: "index",
  metaInfo: {
    title: '招贤纳士'
  },
  components: {
    TagBig
  },
  data() {
    return {
      navData: {
        categoryName: '招贤纳士',
        children: [{
          categoryName:"人才培养",
          path:"/recruit/train/train",
          type:"train"
        },{
          categoryName:"校园招聘",
          path:"/recruit/list/school",
          type:"school"
        },{
          categoryName:"社会招聘",
          path:"/recruit/list/social",
          type:"social"
        }]
      },
    }
  },
}
</script>

<style scoped>

</style>
